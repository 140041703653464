import React, { useEffect, useState } from "react";
import { Label } from "../../../../../@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../@/components/ui/card";
import { MdCancel, MdOutlineAddBox } from "react-icons/md";
import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useMedia } from "react-use";
import { Product, ProductRequest, ProductSearchRequest } from "services/API";
import { addToCart, searchProducts } from "store/searchProductSlice";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../.././../../@/components/ui/command";
import { Trash2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

interface AddLeadCardProps {
  onCancel: () => void;
}

export default function AddNewOrderCard({ onCancel }: AddLeadCardProps) {
  const dispatch: AppDispatch = useDispatch();
  const business = useSelector((state: RootState) => state.auth.business);
  //const business= "67319bb2f75c865d4752b09f";
  const userID = useSelector((state: RootState) => state.selectedUser.userId);
  const products = useSelector(
    (state: RootState) => state.searchProduct.products
  );
  const searchStatus = useSelector(
    (state: RootState) => state.searchProduct.status
  );

  const isMobile = useMedia("(max-width: 640px)");

  console.log("productssss:", products);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  
  
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  
  const [productQuantities, setProductQuantities] = useState<{
    [key: string]: number;
  }>({});
  const { toast } = useToast();

  useEffect(() => {
    if (business) {
      // Initial search to load all products
      const searchParams: ProductSearchRequest = {
        query: "test",
        filters: {
          productFields: {},
          variantFields: {},
          sortFields: [],
        },
      };
      console.log("product list", business, searchParams);
      dispatch(searchProducts({ business, searchParams }));
    }
  }, [business, dispatch]);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    if (business) {
      const searchParams: ProductSearchRequest = {
        query: value,
        filters: {
          productFields: {},
          variantFields: {},
          sortFields: [],
        },
      };
      dispatch(searchProducts({ business, searchParams }));
    }
  };

  const handleSelectProduct = (product: Product) => {
    const isProductSelected = selectedProducts.some(p => p._id === product._id);
    console.log("jjj",isProductSelected);

    if (isProductSelected) {
      toast({
        title: "Product Already Added",
        description: `${product.name} is already in your order list.`,
        className: "bg-red-100 text-red-900 border border-red-200",
      });
      
      return;
    }

    setSelectedProducts((prev) => [...prev, product]);
    setProductQuantities((prev) => ({
      ...prev,
      [product._id]: 1,
    }));
    setIsFocused(false);
  };

  const updateQuantity = (productId: string, newQuantity: number) => {
    if (newQuantity < 1) return;
    setProductQuantities((prev) => ({
      ...prev,
      [productId]: newQuantity,
    }));
  };

  

  const handleSaveClick = async () => {
    if (!business || !userID || selectedProducts.length === 0) {
      toast({
        title: "Error",
        description: "Missing required information to create order",
        className: "bg-red-100 text-red-900 border border-red-200",
      });
      return;
    }

    setLoading(true);
    
    try {
      const cartBody: ProductRequest = {
        productDetails: selectedProducts.map(product => ({
          sku: product.variants[0].sku, 
          quantity: productQuantities[product._id] || 1
        }))
      };

      // Dispatch the addToCart action
      await dispatch(addToCart({ business, userID, cartBody })).unwrap();
      
      toast({
        title: "Success",
        description: "Products added to cart successfully",
        className: "bg-green-100 text-green-900 border border-green-200",
      });
      
      setSelectedProducts([]);
      setProductQuantities({});
      onCancel(); 
      
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to add products to cart",
        className: "bg-red-100 text-red-900 border border-red-200",
      });
    } finally {
      setLoading(false);
    }
  };

  const removeProduct = (productId: string) => {
    setSelectedProducts((prev) => prev.filter((p) => p._id !== productId));
    setProductQuantities((prev) => {
      const updated = { ...prev };
      delete updated[productId];
      return updated;
    });
  };

  return (
    <Card
      className={`${
        isMobile ? "h-[90vh]" : "h-[78vh]"
      } p-1 mt-0 mb-2 shadow-sm rounded-lg border border-gray-200`}
    >
      <div className="flex justify-end pr-6 pt-4 rounded-b-lg">
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center mr-4"
          onClick={onCancel}
        >
          <MdCancel className="mr-2" size={16} />
          Cancel
        </button>
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center"
          onClick={handleSaveClick}
          disabled={loading || selectedProducts.length === 0}
        >
          <MdOutlineAddBox className="mr-2" size={16} />
          {loading ? "Saving..." : "Add"}
        </button>
      </div>
      <ScrollArea className="h-[70vh]">
        <CardHeader className="pb-4 pl-6 pr-6 pt-2">
          <CardTitle className="text-sm font-bold text-gray-800 capitalize">
            Add New Order
          </CardTitle>
          <CardDescription>
            Please fill in the details for the order.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-5 px-6">
          <div>
            <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4">
              Product Details
            </div>
            <Label htmlFor="sku" className="text-sm">
              Products
            </Label>
            <div className="space-y-4">
              <div className="mb-4">
                <Command className="rounded-lg border">
                  <CommandInput
                    placeholder="Search products..."
                    value={searchTerm}
                    onValueChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                  />
                  {isFocused && (
                    <CommandList>
                      <CommandEmpty>No products found.</CommandEmpty>
                      <CommandGroup heading="Products">
                        {searchStatus === "loading" && (
                          <CommandItem disabled className="text-center py-4">
                            Loading products...
                          </CommandItem>
                        )}
                        {searchStatus === "failed" && (
                          <CommandItem
                            disabled
                            className="text-center py-4 text-red-500"
                          >
                            Failed to load products
                          </CommandItem>
                        )}
                        {products.map((product) => (
                          <CommandItem
                            key={product._id}
                            onSelect={() => handleSelectProduct(product)}
                          >
                            <span>{product.name} </span>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  )}
                </Command>
              </div>
              {selectedProducts.map((product) => {
                const firstVariant = product.variants?.[0];
                const currentQuantity = productQuantities[product._id] || 1;

                return (
                  <div
                    key={product._id}
                    className="flex items-center justify-between p-2 border bg-zinc-100 rounded-md border-zinc-300"
                  >
                    <div className="text-xs">
                      <div className="font-medium text-gray-800">
                        {product.name}
                      </div>
                      <div className="text-gray-600">
                        {firstVariant
                          ? `SKU: ${firstVariant.sku} - Rs.${(
                              firstVariant.price / 100
                            ).toFixed(2)}`
                          : "No variant available"}
                      </div>

                      <div className="flex items-center space-x-2 text-gray-600">
                        Quantity:
                        <button
                          className="px-2 py-1 text-gray-700 rounded-md text-xs hover:bg-zinc-200"
                          onClick={() =>
                            updateQuantity(product._id, currentQuantity - 1)
                          }
                          disabled={currentQuantity <= 1}
                        >
                          −
                        </button>
                        <span className="text-xs font-semibold">
                          {currentQuantity}
                        </span>
                        <button
                          className="px-2 py-1 text-gray-700 rounded-md text-xs hover:bg-zinc-200"
                          onClick={() =>
                            updateQuantity(product._id, currentQuantity + 1)
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="text-gray-600 ">
                        Price: Rs.
                        {((firstVariant.price / 100) * currentQuantity).toFixed(
                          2
                        )}
                      </div>
                    </div>

                    <button
                      className="text-red-500 hover:text-red-300 text-xs"
                      onClick={() => removeProduct(product._id)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          
        </CardContent>
      </ScrollArea>
    </Card>
  );
}
