import axios, { AxiosResponse } from "axios"
const API_URL = process.env.REACT_APP_API_KEY
//console.log("API URL: ",API_URL);

// const API_URL = 'https://233evl980e.execute-api.ap-southeast-1.amazonaws.com/staging'
//const API_URL = 'https://233evl980e.execute-api.ap-southeast-1.amazonaws.com'//production
//const API_URL = 'http://3.0.158.95:3000' //staging

//https://233evl980e.execute-api.ap-southeast-1.amazonaws.com/bot
//zappy - 668e5ae5e887a65ae9ff95d0
//macson - 66a0ac298364d9bf97908756
//test - 66c59e078364d9bf9790ae97
// axios.defaults.withCredentials = true;

// login
export interface ProviderStatus {
  name: string
  status: string
}

export interface Bot {
  _id: string
  name: string
  is_active: boolean
  chat_provider_status: ProviderStatus[]
}

export interface LoginResponse {
  accessToken: string
  bots: Bot
  business: string
  user_id: string
}
export const loginApi = async (
  email: string,
  password: string
): Promise<LoginResponse> => {
  const response = await axios.post<LoginResponse>(`${API_URL}/auth/login`, {
    email,
    password,
  })
  console.log("sussceful", response)
  return response.data
}

//get the chat list
export interface ChatItem {
  chatroomId: string
  name: string
  userMobileNumber: string
  status: string
  lastMessage: {
    message: string;
    sender: string;
    isRead: boolean;
  };
  lastActivity: string
  userId: string
}

export interface ChatListResponse {
  chats: ChatItem[]
  totalPages: number
  currentPage: number
  isECommerce: boolean
}

export const fetchChatListApi = async (
  label: string,
  limit: number,
  page: number,
  templateId: string,
  leadOwnerId: string,
  token: string
): Promise<ChatListResponse> => {
  try {
    const response = await axios.get<ChatListResponse>(
      `${API_URL}/chat?label=${label}&limit=${limit}&page=${page}&template=${templateId}&leadOwner=${leadOwnerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("template myyyy",templateId,label)
    console.log("Chat list fetched successfully", response)
    return response.data
  } catch (error: any) {
    console.error(
      "Error fetching chat list:",
      error.message,
      error.response?.data
    )
    throw error
  }
}

//get the message list
export interface Media {
  url:string
  mimetype: string
  filename: string
  caption: string
}

export interface MessageItem {
  messageId: string
  sender: string
  body: string
  timestamp: string
  media: Media[]
}

export interface MessageListResponse {
  messages: MessageItem[]
  totalPages: number
  currentPage: number
  hasNextPage: boolean
  hasPrevPage: boolean
  totalMessagesCount: number
}

export const fetchMessageListApi = async (
  chatroomId: string,
  limit: number,
  page: number
): Promise<MessageListResponse> => {
  const response = await axios.get<MessageListResponse>(
    `${API_URL}/message/${chatroomId}?limit=${limit}&page=${page}`
  )
  console.log("Message list fetched successfully", response)
  console.log(
    "Message list fetched successfully",
    response.data.totalMessagesCount
  )
  return response.data
}

// Add log activity
export interface ActivityLogRequest {
  title: string
  description: string
  userId: string
}

export interface ActivityLogResponse {
  success: boolean
  message: string
}

export const postActivityLogApi = async (
  title: string,
  description: string,
  user: string
): Promise<ActivityLogResponse> => {
  const response = await axios.post<ActivityLogResponse>(
    `${API_URL}/activity-log`,
    {
      title,
      description,
      user,
    }
  )
  console.log("Activity log posted successfully", response)
  return response.data
}

// get all log activities
export interface ActivityLogItem {
  _id: string
  title: string
  description: string
  timestamp: string
}

export const fetchLogActivitiesApi = async (
  userId: string
): Promise<ActivityLogItem[]> => {
  const response = await axios.get<ActivityLogItem[]>(
    `${API_URL}/activity-log/${userId}`
  )
  console.log("Log activities fetched successfully", response.data)
  return response.data
}

//get the lead templates
export interface LeadFieldItem {
  id: string
  name: string
  required: boolean
  type: string
}

export interface TemplateItem {
  lead_template_id: string
  lead_template_name: string
  lead_template_status_list: string[]
  fields: LeadFieldItem[]
}

export interface LeadTeamplateListResponce {
  templates: TemplateItem[]
}

export const fetchLeadTemplateList = async (
  business: string
): Promise<LeadTeamplateListResponce> => {
  const response = await axios.get<LeadTeamplateListResponce>(
    `${API_URL}/business/${business}/lead_fields`
  )
  console.log("Lead template details fetched successfully", response)
  return response.data
}

//get selected user lead details
export interface LeadField {
  id: string
  name: string
  required: boolean
  type: string
}

// export interface RequiredFieldTemplate {
//   id: string;
//   name: string;
//   bot_id: string;
//   status_list: string[];
//   fields: LeadField[];
// }

// export interface Business {
//   name: string;
//   required_fields: RequiredFieldTemplate[];
// }

export interface UserField {
  [key: string]: string | null
}

export interface ExistingFieldTemplate {
  id: string
  name: string
  status: string
  fields: UserField
  owner: {}
  requiredFields: LeadField[]
}

// export interface User {
//   existing_fields: ExistingFieldTemplate[];
// }

export interface LeadDataResponse {
  mobile: string
  lead_data: ExistingFieldTemplate[]
}

export const fetchSelectedUserLeadTemplateData = async (
  userId: string
): Promise<LeadDataResponse[]> => {
  try {
    const response = await axios.get<LeadDataResponse[]>(
      `${API_URL}/user/${userId}/lead-data`
    )
    return response.data
  } catch (error) {
    console.error("Error fetching lead data:", error)
    throw error
  }
}

//patch the selected user's lead template data
interface UpdateLeadData {
  business_id: string
  lead_id: string
  status: string
  data: Record<string, string>
}

export const updateLeadDetails = async (
  userId: string,
  updateData: UpdateLeadData
): Promise<void> => {
  try {
    const response = await axios.patch(
      `${API_URL}/user/${userId}/lead-data`,
      updateData
    )
    console.log("Lead details updated successfully:", response.data)
  } catch (error) {
    console.error("Error updating lead details:", error)
    throw error
  }
}

//get the admin list
export interface Admin {
  _id: string
  name: string
}

export const fetchAdminListApi = async (
  business: string,
  token: string
): Promise<AxiosResponse<Admin[]>> => {
  try {
    const response = await axios.get<Admin[]>(
      `${API_URL}/business/${business}/admins`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("Admin list fetched successfully", response)
    return response
  } catch (error) {
    console.error("Error fetching admin list:", error)
    throw error
  }
}

// get the status of AI reply
export interface StopReplyResponse {
  stopReplying: boolean
}

export const stopReplyApi = async (
  chatroomId: string
): Promise<AxiosResponse<StopReplyResponse>> => {
  try {
    const response = await axios.get<StopReplyResponse>(
      `${API_URL}/chat/${chatroomId}/stop-reply`
    )
    //console.log("reply action successful", response);
    return response
  } catch (error) {
    console.error("Error stopping AI reply:", error)
    throw error
  }
}

//update stop-reply status
export interface StopReplyResponse {
  stopReplying: boolean
}

export const updateStopReplyApi = async (
  chatroomId: string,
  stopReply: boolean
): Promise<AxiosResponse<StopReplyResponse>> => {
  try {
    const response = await axios.put<StopReplyResponse>(
      `${API_URL}/chat/${chatroomId}/stop-reply`,
      { stopReply }
    )
    console.log("AI reply status updated successfully", response)
    return response
  } catch (error) {
    console.error("Error updating AI reply status:", error)
    throw error
  }
}

// get orders for selected user
export interface ProductDetail {
  name: string
  quantity: string
  sku: string
  sale_price: number
  total_sale: number
}

export interface OrderDetail {
  [key: string]: string | boolean; 
}


export interface Status {
  activity: string
  timestamp: number
}

export interface BillingDetails {
  name: string
  phone: string
  address: string
  delivery_charge: number
}

export interface OrderField {
  id: string
  name: string
  required: boolean
  type: string
}

export interface OrderTemplate {
  id:string
  name:string
  fields: OrderField
}

export interface OrderItem {
  _id: string
  user_id: string
  business_id: string
  product_details: ProductDetail[]
  sale_price: number
  status: Status[]
  billing_details?: BillingDetails
  order_details: OrderDetail
  __v: number
  template: OrderTemplate
}

export const fetchOrdersApi = async (userId: string): Promise<OrderItem[]> => {
  const response = await axios.get<OrderItem[]>(
    `${API_URL}/user/${userId}/orders`
  )
  console.log("Orders fetched successfully", response.data)
  return response.data;
}

//get all order list
export interface OrderStatus {
  activity: string
  timestamp: number
}

export interface OrderItem {
  _id: string
  sale_price: number
  status: Status[]
  user: string[]
  billing_details?: BillingDetails
  product_details: ProductDetail[]
}

export interface OrdersResponse {
  orders: OrderItem[]
  totalMessagesCount: number
  totalPages: number
  hasNextPage: boolean
  hasPrevPage: boolean
}

export const fetchOrdersListApi = async (
  time: string,
  status: string,
  token:string,
  page: number,
): Promise<OrdersResponse> => {
  try {
    const response = await axios.get<OrdersResponse>(
      `${API_URL}/business/dashboard/orders?time=${time}&status=${status}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("Order list fetched successfully", response)
    return response.data
  } catch (error: any) {
    console.error(
      "Error fetching orderlist:",
      error.message,
      error.response?.data
    )
    throw error
  }
}

//get the selected order(order page)
export interface SelectedOrderProductDetail {
  name: string
  quantity: string
  sku: string
  sale_price: number
  total_sale: number
}

export interface SelectedOrderDetail {
  paymentmethod: string;
}

export interface SelectedOrderStatus {
  activity: string
  timestamp: number
}

export interface SelectedOrderBillingDetails {
  name: string
  phone: string
  address: string
  delivery_charge: number
}

export interface SelectedOrderItem {
  _id: string;
  user_id: string;
  business_id: string;
  product_details: SelectedOrderProductDetail[];
  sale_price: number;
  status: SelectedOrderStatus[];
  billing_details?: BillingDetails;
  order_details: OrderDetail;
  __v: number;
}

interface ApiResponse<T> {
  data: T;
  message: string;
}

export const fetchSelectedOrderApi = async (
  orderId: string
): Promise<SelectedOrderItem> => {
  const response = await axios.get<ApiResponse<SelectedOrderItem[]>>(
    `${API_URL}/order/${orderId}`
  );
  console.log("Selected orders fetched successfully", response.data);
  return response.data.data[0];
};

//update the order status
export const updateOrderStatusApi = async (
  orderId: string,
  status: string,
): Promise<void> => {
  try {
    const response = await axios.post(`${API_URL}/order/${orderId}`, { status })
    console.log("Order status updated successfully", response.data)
  } catch (error) {
    console.error("Error updating order status", error)
  }
}

//get the current QR
export const fetchQrLinkApi = async (token: string): Promise<string> => {
  try {
    const response = await axios.get<{ qrLink: string }>(
      `${API_URL}/business/qr/code`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("QR link fetched successfully", response.data.qrLink)
    return response.data.qrLink
  } catch (error) {
    console.error("Error fetching QR link", error)
    throw error
  }
}

//refresh the QR link
export const refreshQrLinkApi = async (token: string): Promise<string> => {
  try {
    const response = await axios.post<{ qrLink: string }>(
      `${API_URL}/business/qr/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.qrLink
  } catch (error) {
    throw error
  }
}

//update stop-reply status
export interface StopBotResponse {
  isActive: boolean
}

export const updateStopBotReply = async (
  botId: string,
  isActive: boolean
): Promise<AxiosResponse<StopBotResponse>> => {
  try {
    const response = await axios.patch<StopBotResponse>(
      `${API_URL}/bot/${botId}/is-active`,
      { isActive }
    )
    console.log("Bot reply status updated successfully", response)
    return response
  } catch (error) {
    console.error("Error updating bot status:", error)
    throw error
  }
}

// get the status of Bot
export interface StopBotReplyResponse {
  is_active: boolean
}

export const stopBotReplyApi = async (botId: string): Promise<boolean> => {
  try {
    const botData = await axios.get<{ is_active: boolean }>(`${API_URL}/bot/${botId}`);
    return botData.data.is_active; 
  } catch (error) {
    console.error("Error fetching Bot reply status:", error);
    throw error;
  }
};

// get the status of Bot
export interface PluginDetails {
  plugin_name: string
  value: string
  active: boolean
}

export const getBotPlugins = async (botId: string): Promise<PluginDetails[]> => {
  try {
    const response = await axios.get<{ plugins: PluginDetails[] }>(
      `${API_URL}/bot/${botId}`
    );
    console.log("Fetched plugins successfully", response.data.plugins);
    return response.data.plugins;
  } catch (error) {
    console.error("Error fetching plugins:", error);
    throw error;
  }
};

//update the plugin value
export interface PluginUpdateRequest {
  bot_id: string;
  plugin_name: string;
  value: string;
}

export const updatePluginStatus = async (
  requestBody: PluginUpdateRequest
): Promise<void> => {
  try {
    const response = await axios.post(`${API_URL}/plugin/value`, requestBody);
    console.log("Plugin status updated successfully:", response.data);
  } catch (error) {
    console.error("Error updating plugin status:", error);
    throw error;
  }
};

//get the order csv
export const downloadOrdersCsv = async (
  business: string,
  token: string,
  status: string,
  time: string 
): Promise<void> => {
  try {
    const response = await axios.get(
      `${API_URL}/business/dashboard/orders/export`, {
        params: { id: business, status, time },
        responseType: 'blob',
        headers: {
          'Accept': 'text/csv',
          'Authorization': `Bearer ${token}` 
        }
    });
    //Blob -  (Binary Large Object)
    if (!response.data || response.data.size === 0) {
      throw new Error('No data received from server');
    }

    const blob = new Blob([response.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    
    const filename = `orders_${new Date().toISOString().split('T')[0]}.csv`;
    link.setAttribute('download', filename);
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading orders CSV:", error);
    throw error;
  }
};

//update read status
export interface ReadStatusResponse {
  status: boolean
}

export const updateReadStatusApi = async (
  chatroomId: string,
  status: boolean
): Promise<AxiosResponse<ReadStatusResponse>> => {
  try {
    const response = await axios.put<ReadStatusResponse>(
      `${API_URL}/chat/${chatroomId}/read-status`,
      { status }
    )
    console.log("Read status updated successfully", response)
    return response
  } catch (error) {
    console.error("Error updating Read status:", error)
    throw error
  }
}

//get the product list
//request
interface ProductSearchFilters {
  productFields: Record<string, any>;
  variantFields: Record<string, any>;
  sortFields: any[];
}

export interface ProductSearchRequest {
  query: string;
  filters: ProductSearchFilters;
}

//response
interface ProductVariant {
  sku: string;
  price: number;
  ingredients?: string;
}

interface ProductDetails {
  [key: string]: any;
}

export interface Product {
  _id: string;
  name: string;
  details: ProductDetails;
  variants: ProductVariant[];
}

interface ProductSearchResponse {
  searchResult: Product[];
}

export const searchProductsApi = async (
  business: string,
  searchParams: ProductSearchRequest
): Promise<AxiosResponse<ProductSearchResponse>> => {
  try {
    const response = await axios.post<ProductSearchResponse>(
      `${API_URL}/product/search-vector/${business}`,
      searchParams
    );
    console.log("Products fetched successfully", response);
    return response;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}

//Add products to the cart
interface ProductDetails{
  sku:string;
  quantity:number;
}

export interface ProductRequest{
  productDetails: ProductDetails[];
}

export const addCartApi = async (
  business: string,
  userID: string,
  cartBoady: ProductRequest
): Promise<void> => {
  try {
    const response = await axios.post(`${API_URL}/order/cart/${business}/${userID}`,cartBoady);
    console.log("Add to cart",response);
  } catch(error) {
    console.log("Error",error);
    throw error;
  }
}