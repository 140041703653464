import { CircleUser } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../../../@/components/ui/avatar";
import { Badge } from "../../../../../@/components/ui/badge";
import { Card, CardContent } from "../../../../../@/components/ui/card";
import { Separator } from "../../../../../@/components/ui/separator";
import { format, isToday, isYesterday } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

function formatDate(timestamp: string): string {
  const date = new Date(timestamp);

  if (isToday(date)) {
    return format(date, "p");
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return format(date, "dd/MM/yyyy");
  }
}
//"Partially order": "created",
//"New order": "confirm",
const eCommerceStatusLabels: Record<string, string> = {
  created: "Partial Order",
  confirm: "New Order",
  confirmed: "Confirmed",
  cancelled: "Cancelled",
  dispatched: "Dispatched",
};

interface ChatCardProps {
  chat: any;
  selectedChatroomId: string | null;
  onChatClick: (
    chatroomId: string,
    userId: string,
    userMobileNumber: string,
    isRead: boolean
  ) => void;
}

export default function ChatCardComponent({
  chat,
  selectedChatroomId,
  onChatClick,
}: ChatCardProps) {
  const avaterImage = "";
  const isECommerce = useSelector((state: RootState) => state.chat.isECommerce);
  const displayedStatus = isECommerce
    ? eCommerceStatusLabels[chat.status] || chat.status
    : chat.status;

  return (
    <Card
      onClick={() =>
        onChatClick(
          chat.chatroomId,
          chat.userId,
          chat.userMobileNumber,
          chat.lastMessage.isRead
        )
      }
      className={`border-0 hover:bg-gray-100 transition-colors duration-200 cursor-pointer ${
        selectedChatroomId === chat.chatroomId ? "bg-zinc-200" : ""
      }`}
    >
      <CardContent className="flex items-center gap-2 m-3 p-0">
        <Avatar className="h-10 w-10 bg-transparent">
          {avaterImage ? (
            <AvatarImage
              src={avaterImage}
              alt={`${chat.userMobileNumber} Avatar`}
            />
          ) : (
            <AvatarFallback>
              <CircleUser className="h-7 w-7" />
            </AvatarFallback>
          )}
        </Avatar>
        <div className="grid gap-1">
          <p
            className="text-sm font-medium leading-none truncate w-28"
            title={chat.userMobileNumber}
          >
            {chat.userMobileNumber}
          </p>
        </div>

        <div className="ml-auto font-medium flex flex-col items-end relative">
          
            <div className="flex items-center gap-1">
            {chat.status && (
              <Badge
                variant="outline"
                className="text-slate-400 text-[0.6rem] font-[300] border-slate-300 border"
              >
                {displayedStatus}
              </Badge>
              )}
              {chat.lastMessage?.isRead === false && (
                <span className="h-3 w-3 bg-green-500 rounded-full animate-pulse"></span>
              )}
            </div>
          
          <p className="text-xs text-muted-foreground mt-1">
            {formatDate(chat.lastActivity)}
          </p>
        </div>
      </CardContent>
      <Separator />
    </Card>
  );
}
