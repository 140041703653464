import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { updateReadStatusApi, ReadStatusResponse } from "../services/API";

interface ReadStatusState {
  isRead: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: ReadStatusState = {
  isRead: false,
  loading: false,
  error: null,
};

// Async thunk for updating the read status
export const updateReadStatus = createAsyncThunk<
  ReadStatusResponse,
  { chatroomId: string; status: boolean }
>(
  "readStatus/updateReadStatus",
  async ({ chatroomId, status }, { rejectWithValue }) => {
    try {
      const response = await updateReadStatusApi(chatroomId, status);
      console.log("okay",response.data)
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update read status");
    }
  }
);

const readStatusSlice = createSlice({
  name: "readStatus",
  initialState,
  reducers: {
    setReadStatus(state, action: PayloadAction<boolean>) {
      state.isRead = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateReadStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateReadStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.isRead = action.payload.status;
      })
      .addCase(updateReadStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setReadStatus } = readStatusSlice.actions;
export default readStatusSlice.reducer;
