//import { Button } from "../../../../../@/components/ui/button";
import React, { useState } from "react";
import { PiShoppingCartSimpleFill } from "react-icons/pi";
import AddNewLeadCard from "./AddNewOrderDataCard";
import { Button } from "../../../../../@/components/ui/button";

export default function NoOrderComponent() {
  const [showCard, setShowCard] = useState(false);
  // const business = useSelector((state: RootState) => state.auth.business);
  // const userId = useSelector((state: RootState) => state.selectedUser.userId);

  // console.log("Business in:", business); 
  // console.log("UserId in :", userId);

  const handleAddTemplateData = () => {
    setShowCard(true);
  };

  const handleCancel = () => {
    setShowCard(false);
  };
  return (
    <>
      {!showCard ? (
        <div className="flex items-center justify-center h-[80vh] w-full bg-white">
          <div className="text-center">
            <PiShoppingCartSimpleFill className="text-gray-300 mx-auto" size={50} />
            <h3 className="text-sm font-semibold text-gray-500 mt-4">
              No orders available.
            </h3>
            <Button onClick={handleAddTemplateData} className="mt-4 text-sm">
              Add New Order
            </Button>
          </div>
        </div>
      ) : (
        <div>
            <AddNewLeadCard onCancel={handleCancel}/>
        </div>
      )}
    </>
  );
}
