import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { AppDispatch, RootState } from "store/store";
import { Checkbox } from "../../../../../@/components/ui/checkbox";
import { updateReadStatus } from "store/readStatusSlice";
import { updateReadStatusSlice } from "store/chatSlice";
import { updateSelectedUserReadStatus } from "store/SelectedChatUserSlice";

export default function ReadStatusComponent() {
  const dispatch: AppDispatch = useDispatch();
  const chatroomId = useSelector(
    (state: RootState) => state.selectedUser.chatroomId
  );
  const readStatus = useSelector(
    (state: RootState) => state.selectedUser.isRead
  );

  const handleCheckboxChange = () => {
    if (!chatroomId) {
      return;
    }
    const newReadStatus = !readStatus;

    dispatch(
      updateReadStatus({ chatroomId: chatroomId, status: newReadStatus })
    );
    dispatch(
      updateReadStatusSlice({
        chatroomId: chatroomId,
        status: newReadStatus,
      })
    );
    dispatch(updateSelectedUserReadStatus(newReadStatus));
  };

  console.log("read", readStatus);

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id="readStatus"
        checked={readStatus ?? false}
        onCheckedChange={handleCheckboxChange}
      />
      <label htmlFor="readStatus" className="text-sm font-medium">
        {/* {readStatus ? "Read" : "Unread"} */}
        Checked
      </label>
    </div>
  );
}
