import { Copy, X } from "lucide-react";
import { Button } from "../../../../@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../@/components/ui/card";
import React, { useEffect } from "react";
import { Separator } from "../../../../@/components/ui/separator";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchSelectedOrder, setSelectedOrderId } from "store/OrdersSlice";
import { format } from "date-fns";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";
import { useMedia } from "react-use";

const formatTimestamp = (timestamp: number) => {
  return format(new Date(timestamp), "dd MMM yyyy, HH:mm");
};

export default function SelectedOrderDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const selectedOrderId = useSelector(
    (state: RootState) => state.ordersList.selectedOrderId
  );
  const selectedOrder = useSelector(
    (state: RootState) => state.ordersList.selectedOrder
  );
  console.log("hklajen", selectedOrder);
  console.log("Selected Order ID:", selectedOrderId);

  type StatusKey =
    | "created"
    | "confirm"
    | "confirmed"
    | "cancelled"
    | "dispatched";

  const statusMapping: Record<StatusKey, string> = {
    created: "Partial Order",
    confirm: "New Order",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    dispatched: "Dispatched",
  };

  useEffect(() => {
    if (selectedOrderId) {
      dispatch(fetchSelectedOrder(selectedOrderId));
    }
  }, [dispatch, selectedOrderId]);

  console.log("Selected Order ID:", selectedOrderId);
  console.log("Selected Order:", selectedOrder);

  // const formatPaymentMethod = (paymentMethod: string) => {
  //   return paymentMethod.replace("_", " ");
  // };

  const isMobile = useMedia("(max-width: 900px)");

  const handleClose = () => {
    dispatch(setSelectedOrderId(null)); 
  };

  return (
    <div className="border rounded-lg">
      <ScrollArea
        className=" border-1"
        style={{ height: isMobile ? "85vh" : "67vh" }}
      >
        {selectedOrder && (
          <Card
            className="overflow-hidden border-0"
            x-chunk="dashboard-05-chunk-4"
          >
            <CardHeader className="flex flex-row items-start bg-muted/50">
              <div className="grid gap-0.5">
                <CardTitle className="group flex items-center gap-2 text-sm">
                  Order {selectedOrder._id}
                  <Button
                    size="icon"
                    variant="outline"
                    className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
                  >
                    <Copy className="h-3 w-3" />
                    <span className="sr-only">Copy Order ID</span>
                  </Button>
                </CardTitle>

                <CardDescription className="text-xs">
                  {selectedOrder.status.length > 0 && (
                    <>
                      <span>
                        Status:{" "}
                        {statusMapping[
                          selectedOrder.status[selectedOrder.status.length - 1]
                            .activity as StatusKey
                        ] || "Unknown Status"}
                      </span>
                      <span className="ml-2 text-xs text-gray-500">
                        Date:{" "}
                        {formatTimestamp(
                          selectedOrder.status[selectedOrder.status.length - 1]
                            .timestamp
                        )}
                      </span>
                    </>
                  )}
                </CardDescription>
              </div>
              {/* <div className="ml-auto flex items-center gap-1">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button size="icon" variant="outline" className="h-8 w-8">
                      <MoreVertical className="h-3.5 w-3.5" />
                      <span className="sr-only">More</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem>Edit</DropdownMenuItem>
                    <DropdownMenuItem>Export</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>Trash</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div> */}

              <Button
                size="icon"
                variant="outline"
                className="absolute top-2 right-2"
                 onClick={handleClose}
              >
                <X className="h-8 w-6" />
                <span className="sr-only">Close</span>
              </Button>

            </CardHeader>
            <CardContent className="p-6 text-sm">
              <div className="grid gap-3">
                {selectedOrder.product_details &&
                  selectedOrder.product_details.length > 0 && (
                    <>
                      <div className="font-semibold">Order Details</div>
                      {selectedOrder.product_details?.map((product, index) => (
                        <div key={index}>
                          <ul className="grid gap-3">
                            <li className="flex items-center justify-between">
                              <span className="text-muted-foreground">
                                {product.name} x <span>{product.quantity}</span>
                              </span>
                              <span>
                                Rs.{(product.sale_price / 100).toFixed(2)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      ))}
                      <Separator className="my-2" />
                    </>
                  )}

                {(selectedOrder.sale_price ||
                  selectedOrder.billing_details) && (
                  <>
                    <ul className="grid gap-3">
                      <li className="flex items-center justify-between">
                        <span className="text-muted-foreground">Subtotal</span>
                        <span>
                          Rs.{(selectedOrder.sale_price / 100).toFixed(2)}
                        </span>
                      </li>
                      {selectedOrder.billing_details && (
                        <li className="flex items-center justify-between">
                          <span className="text-muted-foreground">
                            Shipping
                          </span>
                          <span>
                            Rs.
                            {(
                              selectedOrder.billing_details.delivery_charge /
                              100
                            ).toFixed(2)}
                          </span>
                        </li>
                      )}

                      <li className="flex items-center justify-between font-semibold">
                        <span className="text-muted-foreground">Total</span>
                        <span>
                          Rs.
                          {(
                            selectedOrder.sale_price / 100 +
                            (selectedOrder.billing_details?.delivery_charge ||
                              0) /
                              100
                          ).toFixed(2)}
                        </span>
                      </li>
                    </ul>
                    <Separator className="my-4" />
                  </>
                )}

                {selectedOrder.billing_details?.address && (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      {selectedOrder.billing_details && (
                        <>
                          <div className="grid gap-3">
                            <div className="font-semibold">
                              Shipping Information
                            </div>

                            <address className="grid gap-0.5 not-italic text-muted-foreground break-words whitespace-normal">
                              {selectedOrder.billing_details.address}
                            </address>
                          </div>
                          <div className="grid auto-rows-max gap-3">
                            <div className="font-semibold">
                              Billing Information
                            </div>
                            <div className="text-muted-foreground">
                              Same as shipping address
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <Separator className="my-4" />
                  </>
                )}

                {selectedOrder.billing_details?.name &&
                  selectedOrder.billing_details?.phone && (
                    <>
                      <div className="grid gap-3">
                        {selectedOrder.billing_details && (
                          <>
                            <div className="font-semibold">
                              Customer Information
                            </div>
                            <dl className="grid gap-3">
                              <div className="flex items-center justify-between">
                                <dt className="text-muted-foreground">
                                  Customer
                                </dt>
                                <dd>{selectedOrder.billing_details.name}</dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-muted-foreground">Phone</dt>
                                <dd>
                                  <a href="tel:">
                                    {selectedOrder.billing_details.phone}
                                  </a>
                                </dd>
                              </div>
                            </dl>
                          </>
                        )}
                      </div>
                      <Separator className="my-4" />
                    </>
                  )}
                {/* {selectedOrder.order_details?.paymentmethod && (
                  <>
                    <div className="grid gap-3">
                      <div className="font-semibold">Payment Information:</div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">
                            Payment Method
                          </dt>
                          <dd>
                            {formatPaymentMethod(
                              selectedOrder.order_details.paymentmethod
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </>
                )} */}
                {selectedOrder.order_details && (
                  <div className="grid gap-3">
                    <div className="font-semibold">Additional Information:</div>
                    <dl className="grid gap-3">
                      {Object.entries(selectedOrder.order_details).map(
                        ([key, value]) => (
                          <div
                            key={key}
                            className="flex items-center justify-between"
                          >
                            <dt className="text-muted-foreground capitalize">
                              {key.replace(/_/g, " ")}
                            </dt>
                            <dd>{value !== null ? value.toString().replace(/_/g, " ") : "N/A"}</dd>
                          </div>
                        )
                      )}
                    </dl>
                  </div>
                )}
              </div>
            </CardContent>
            {/* <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
            <div className="text-xs text-muted-foreground">
              Updated <time dateTime="2023-11-23">November 23, 2023</time>
            </div>
            <Pagination className="ml-auto mr-0 w-auto">
              <PaginationContent>
                <PaginationItem>
                  <Button size="icon" variant="outline" className="h-6 w-6">
                    <ChevronLeft className="h-3.5 w-3.5" />
                    <span className="sr-only">Previous Order</span>
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button size="icon" variant="outline" className="h-6 w-6">
                    <ChevronRight className="h-3.5 w-3.5" />
                    <span className="sr-only">Next Order</span>
                  </Button>
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardFooter> */}
          </Card>
        )}
      </ScrollArea>
    </div>
  );
}
