import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { searchProductsApi, Product, ProductSearchRequest, addCartApi, ProductRequest } from "../services/API";

interface ProductState {
  products: Product[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  cartStatus: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: ProductState = {
  products: [],
  status: "idle",
  error: null,
  cartStatus: "idle",
};

export const searchProducts = createAsyncThunk(
  "product/searchProducts",
  async (
    { business,searchParams }: { business: string, searchParams: ProductSearchRequest },
    { getState, rejectWithValue }
  ) => {
    console.log("product business",business)
    if (!business) {
      return rejectWithValue("No business selected");
    }

    try {
      const response = await searchProductsApi(business, searchParams);
      console.log("my products in slice",response)
      return response.data;
    } catch (error: any) {
      console.log("errors in slice",error)
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

//Async thunk for adding product to cart
export const addToCart = createAsyncThunk(
  "product/addToCart",
  async ({ business, userID, cartBody }: { business: string, userID: string, cartBody: ProductRequest }, { rejectWithValue }) => {
    try {
      await addCartApi(business, userID, cartBody);
      return cartBody; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Failed to add to cart");
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetProducts(state) {
      state.products = [];
      state.status = "idle";
      state.error = null;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
        console.log("Searching products...");
      })
      .addCase(
        searchProducts.fulfilled,
        (state, action: PayloadAction<{ searchResult: Product[] }>) => {
          state.status = "succeeded";
          state.products = action.payload.searchResult;
          console.log("Products fetched:", action.payload.searchResult);
        }
      )
      .addCase(
        searchProducts.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload;
          console.error("Failed to fetch products:", action.payload,action);
        }
      )
      .addCase(addToCart.pending, (state) => {
        state.cartStatus = "loading";
      })
      .addCase(addToCart.fulfilled, (state) => {
        state.cartStatus = "succeeded";
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.cartStatus = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetProducts } = productSlice.actions;

export default productSlice.reducer;

// Example usage:
/*
// First set the business
dispatch(setSelectedBusiness("67319bb2f75c865d4752b09f"));

// Then search products
const searchParams = {
  query: "tea set",
  filters: {
    productFields: {},
    variantFields: {},
    sortFields: []
  }
};
dispatch(searchProducts({ searchParams }));
*/