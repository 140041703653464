import { throttle } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../../store/store";
import { fetchChatList } from "../../../../../store/chatSlice";
import { resetMessages, setSelectedChatroom } from "store/messageSlice";
import { setSelectedUserId } from "store/SelectedChatUserSlice";
import ErrorComponent from "../../../errorComponent/ErrorComponent";
import ChatListBoadyLoadingComponent from "./ChatListBodyLoadingComponent";
import EmptyChatListComponent from "./EmptyChatListComponent";
import ChatCardComponent from "./ChatCardComponent";
import LoadingSpinner from "./LoadingSpinner";

interface MyCardProps {
  onChatSelect: () => void;
}

export default function MyCard({ onChatSelect }: MyCardProps) {
  const dispatch: AppDispatch = useDispatch();

  const { chats, status, currentPage, totalPages, searchTerm } = useSelector(
    (state: RootState) => state.chat
  );
  const { selectedTemplateId, selectedStatuses } = useSelector(
    (state: RootState) => state.lead
  );
  const token = useSelector((state: RootState) => state.auth.token);
  //const selectedBotId = useSelector((state: RootState) => state.auth.selectedBotId);
  const selectedAdminId = useSelector(
    (state: RootState) => state.admin.selectedFilterAdminId
  );

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const chatroomId = useSelector((state: RootState) => state.selectedUser.chatroomId);

  const listRef = useRef<HTMLDivElement>(null);
  //const isLoadingMore = useRef(false);
  const isStatusUpdate = useRef(false);

  //update automatically status
  useEffect(() => {
    if (listRef.current && scrollPosition > 0) {
      if (isStatusUpdate.current) {
        listRef.current.scrollTop = scrollPosition;
        isStatusUpdate.current = false;
      }
    }
  }, [chats, scrollPosition]);

  const filteredChats = useMemo(() => {
    return chats.filter(
      (chat) =>
        (chat.userMobileNumber?.toLowerCase() ?? "").includes(
          searchTerm.toLowerCase()
        ) && !chat.userMobileNumber.includes("@g.us")
    );
  }, [chats, searchTerm]);

  const templateId = selectedTemplateId ?? "all";
  const label = selectedStatuses.length > 0 ? selectedStatuses.join(",") : "all";

  useEffect(() => {
    if (token) {
      dispatch(
        fetchChatList({
          label: label,
          limit: 20,
          page: 1,
          templateId: templateId,
          leadOwnerId: selectedAdminId,
        })
      );
    }
  }, [dispatch, token, templateId, label, selectedAdminId]);

  const saveScrollPosition = useCallback(() => {
    if (listRef.current) {
      setScrollPosition(listRef.current.scrollTop);
    }
  }, []);

  useEffect(() => {
    return () => {
      isStatusUpdate.current = false;
    };
  }, []);

  const loadMoreChats = useCallback(() => {
    if (currentPage < totalPages && !isLoadingMore && status !== "loading") {
      //isLoadingMore.current = true;
      saveScrollPosition();
      setIsLoadingMore(true);

      dispatch(
        fetchChatList({
          label: label,
          limit: 20,
          page: currentPage + 1,
          templateId: templateId,
          leadOwnerId: selectedAdminId,
        })
      ).finally(() => {
        setIsLoadingMore(false);
      });
    }
  }, [
    currentPage,
    totalPages,
    status,
    dispatch,
    label,
    templateId,
    selectedAdminId,
    saveScrollPosition,
    isLoadingMore,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 500) {
          loadMoreChats();
        }
      }
    };

    const scrollContainer = listRef.current;
    if (scrollContainer) {
      const throttledHandler = throttle(handleScroll, 200);
      scrollContainer.addEventListener("scroll", throttledHandler);
      return () => {
        scrollContainer.removeEventListener("scroll", throttledHandler);
        throttledHandler.cancel();
      };
    }
  }, [loadMoreChats]);

  const handleChatSelection = (
    chatroomId: string,
    userId: string,
    userMobileNumber: string,
    isRead: boolean,
  ) => {
    //setSelectedChatroomId(chatroomId);
    dispatch(resetMessages());
    dispatch(setSelectedChatroom(chatroomId));
    dispatch(setSelectedUserId({ userId, userMobileNumber, chatroomId, isRead }));
    onChatSelect();
  };

  // if (status === "loading" && currentPage === 1) {
  //   return <ChatListBoadyLoadingComponent />;
  // }

  if (status === "failed") {
    return <ErrorComponent />;
  }

  const isInitialLoading =
    status === "loading" && currentPage === 1 && filteredChats.length === 0;

  return (
    <div ref={listRef} className="flex flex-col h-[82vh] overflow-y-auto">
      {isInitialLoading ? (
        <ChatListBoadyLoadingComponent />
      ) : filteredChats.length === 0 ? (
        <EmptyChatListComponent />
      ) : (
        <>
          {filteredChats.map((chat, index) => (
            <ChatCardComponent
              key={chat.chatroomId}
              chat={chat}
              selectedChatroomId={chatroomId}
              onChatClick={handleChatSelection}
            />
          ))}
          {(status === "loading" || isLoadingMore) && <LoadingSpinner />}
        </>
      )}
    </div>
  );
}
